import Banner from "../../components/InvitationLetter/Banner";
// import { useState } from 'react';
import InvitationLetterMain from "../../components/InvitationLetter/InvitationLetterMain";
import style from './index.module.scss';
import Footer from "../../components/Footer";


const InvitationLetter = () => {
    return (
        <>
            <Banner></Banner>
            <InvitationLetterMain />
            <Footer />
            <div className={style.bottom}></div>
        </>
    )
}

export default InvitationLetter;