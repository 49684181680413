import style from './index.module.scss';
import bg2 from '../../../assets/images/bg2@3x.png';


const Banner = () => {
    return (
        <>
            <div className={style.banner}>
                <img src={bg2} alt="" />
                <h1>课程大纲</h1>
                <h2>AI时代教育改革与新一代计算机人才培养</h2>
            </div>
        </>
    )
}


export default Banner;