

import Banner from '../../components/CourseOutline/Banner';
import CourseMain from '../../components/CourseOutline/CourseMain';
import Footer from '../../components/Footer';

const CourseOutline = () => {
    return (
        <>
            <Banner></Banner>
            <CourseMain />
            <Footer></Footer>


        </>
    )
}



export default CourseOutline;