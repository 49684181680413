import style from './index.module.scss';

import banner from '../../../assets/images/banner@3x.png'
const Banner = () => {
    return (
        <div className={style.banner}>
            <img src={banner} alt="" />
            <span>尊敬的教育工作者：</span>
        </div>
    )
}

export default Banner;