import style from './App.module.scss';
import CourseOutline from './pages/CourseOutline';
import InvitationLetter from './pages/InvitationLetter';
import {
  Routes,
  Route
} from "react-router-dom";
function App() {
  window.addEventListener('orientationchange', setRem);

  window.addEventListener('resize', setRem);

  setRem();

  function setRem() {

    var html = document.querySelector('html');

    var width = html.getBoundingClientRect().width;

    html.style.fontSize = (width * 20) / 375 + 'px';

  };

  return (
    <div className={style.App}>
      <Routes>
        <Route path='/' element={<InvitationLetter />}></Route>
        <Route path='/csdn_invite' element={<InvitationLetter />}></Route>
        <Route path='/course' element={<CourseOutline />}></Route>
      </Routes>
    </div>
  )

}

export default App;
