import style from './index.module.scss';

const Footer = () => {
    return (
        <>

            <footer className={style.footer}>
                <span>©2023开心武汉市科技有限公司</span>
            </footer>
        </>
    )
}

export default Footer;