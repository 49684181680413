import style from './index.module.scss';
import road from '../../../assets/images/路径2@3x.png';
import arrow from '../../../assets/images/arrow-r2.svg';
import back from '../../../assets/images/fhui@3x.png';
import btm2 from '../../../assets/images/btm2@3x.png'
const list = [
    {
        title: '引言：AI技术的发展与教育改革的紧迫性',
        time: '10分钟',
        text: [{
            p: '人工智能发展概述',
            emp: null
        }, {
            p: 'AI技术对教育领域的挑战与机遇',
            emp: null
        }]
    },
    {
        title: 'AI技术在实际工作中的典型应用案例',
        time: '40分钟',
        text: [{
            p: '人工智能在软件开发中的应用案例',
            emp: '如Codex、Copilot X等'
        }, {
            p: '人工智能在设计领域的应用案例',
            emp: '如自动生成设计稿、智能优化布局等'
        }, {
            p: '人工智能在新闻、历史等领域的应用案例',
            emp: '如自动新闻撰写、智能文本分析等'
        }]
    },
    {
        title: '计算机人才培养方案及其重要性',
        time: '30分钟',
        text: [{
            p: '介绍新的计算机人才培养方案',
            emp: null
        }, {
            p: '培养方案对学生能力提升的实际意义',
            emp: null
        }, {
            p: '学生在新教学体系下的应对策略',
            emp: null
        }]
    },
    {
        title: '教育者在AI时代的角色与素质要求',
        time: '20分钟',



        text: [{
            p: '教育者如何引导学生正确理解和应用AI技术',
            emp: null
        }, {
            p: '教育者在AI赋能的学生培养中的关键作用',
            emp: null
        }, {
            p: '提升教育者自身对AI技术的认识与应用水平',
            emp: null
        }]
    },
    {
        title: '互动交流与答疑环节',
        time: '60分钟',
        text: [{
            p: '参与课程的老师提问、分享经验和心得',
            emp: null
        }, {
            p: '针对不同学科的老师，讨论AI技术在其领域的应用和挑战',
            emp: null
        }, {
            p: '探讨如何在课堂教学中更好地融入AI技术',
            emp: null
        }]
    }]
const txtList = [
    '1、帮助教师了解AI技术在实际工作中的应用场景，提升教师对教育改革的认识；',
    '2、让教师认识到学生的能力和考核方式必须改变，以适应未来工作岗位和社会职责的要求；',
    '3、引导教师积极参与新的计算机人才培养方案，为培养新一代人才做出贡献；',
    '4、探讨不同学科领域中AI技术的应用和挑战，为教师提供跨学科的视角；',
    '5、增强教育者之间的交流与合作，分享经验，共同应对AI时代的教育挑战；',
    '6、提高教育者自身对AI技术的认识与应用水平，为培养具备AI技能的学生创造条件。'
]
const CourseMain = () => {
    return (
        <>
            <main className={style.main}>
                <img className={style.road} src={road} alt="" />
                {list.map((item, index) =>
                    <li className={style.list_item} key={index}>
                        <div className={style.item_title}>
                            <div className={style.title_pic}>
                                <span>{index + 1}</span>
                            </div>
                            <h1>{item.title}</h1>
                        </div>
                        <span className={style.time}>{item.time}</span>
                        {item.text.map(item2 =>
                            <li key={item2}>
                                <p className={style.p}>{item2.p}</p>
                                {item2.emp ? <p className={style.emp}>{item2.emp}</p> : ''}
                            </li>
                        )}
                    </li>
                )}
                <div className={style.target}>
                    <div className={style.title}>
                        <span>课程目标</span>
                    </div>
                    <div className={style.content}>
                        {txtList.map(item =>
                            <p className={style.item} key={item}>{item}</p>
                        )}
                        <p className={style.detail}>通过本次线上直播课程，我们希望教育工作者能够对AI技术在实际工作中的应用有更深入的了解，并认识到教育改革的紧迫性。同时，教育者将更加积极地参与新的计算机人才培养方案，并为培养新一代具备AI技能的人才做出贡献。</p>
                    </div>
                </div>
                <a className={style.btn} href="/csdn_invite">
                    <span>查看邀请函</span>
                    <img src={arrow} alt="" />
                </a>
                <a href='/csdn_invite' className={style.back}>
                    <img src={back} alt="" />
                </a>

            </main>
            <img className={style.btm2} src={btm2} alt="" />
        </>
    )
}

export default CourseMain;