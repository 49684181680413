import style from './index.module.scss';
import copyimg from '../../../assets/images/复制@3x.png'
import arrow from '../../../assets/images/arrow-r.svg';
import Toast from '../../Toast';
import { useState } from 'react';
import mailbox from '../../../assets/images/邮件@3x.png';
import btm from '../../../assets/images/btm@3x.png'
import erweima from '../../../assets/images/二维码@3x.png';
const InvitationLetterMain = () => {
    const [isShowToast, setIsShowToast] = useState(false)
    const copy = async (string) => {
        navigator.clipboard.writeText(string)
        setIsShowToast(true)
        const timeout = setTimeout(() => {
            setIsShowToast(false)
        }, 1000)
        // clearTimeout(timeout)
    }

    return (
        <>
            <div className={style.main}>
                <p>我们诚挚地邀请您参加CSDN创新研究院即将举行的线上研讨会——《AI时代教育改革与新一代计算机人才培养》。作为教育领域的专家，您的参与将为我们带来宝贵的经验和见解。</p>
                <ul>
                    <li>
                        <div className={style.list_item}>
                            <div className={style.tag} >
                                <span>主题</span>
                            </div>
                            <p className={style.list_item_text}>AI时代教育改革与新一代计算机人才培养</p>
                        </div>
                    </li>
                    <li>
                        <div className={style.list_item}>
                            <div className={style.tag} >
                                <span>时间</span>
                            </div>
                            <p className={style.list_item_text}>2023年4月15日(周六)  下午2点30分</p>
                        </div>
                    </li>
                    <li>
                        <div className={style.list_item}>
                            <div className={style.tag} >
                                <span>地点</span>
                            </div>
                            <p className={style.list_item_text}>腾讯会议<span>(链接将在报名后发送至您的邮箱）</span></p>
                        </div>
                    </li>
                </ul>
                <div className={style.mode}>
                    <div className={style.mode_title}>
                        <span>报名方式</span>
                    </div>
                    <div className={style.mode_content}>
                        <div className={style.mode_item}>
                            <div className={style.mode_text}>
                                <h1>方式一</h1>
                                <h2>
                                    <span>添加微信: Prompt_AGI</span>

                                    <img onClick={() => { copy('Prompt_AGI') }} src={copyimg} alt="" />
                                </h2>
                                <span>发送姓名、职称、学校及联系方式</span>
                            </div>
                            <img className={style.pic} src={erweima} alt="" />
                        </div>
                        <div className={style.mode_item}>
                            <div className={style.mode_text}>
                                <h1>方式二</h1>
                                <h2>
                                    <span>发送邮件: chenyl@csdn.net</span>

                                    <img onClick={() => { copy('chenyl@csdn.net') }} src={copyimg} alt="" />
                                </h2>
                                <span>发送姓名、职称、学校及联系方式至邮箱</span>
                            </div>

                            <img className={style.pic} src={mailbox} alt="" />

                        </div>
                    </div>
                </div>
                <div className={style.text}>
                    <p>我们期待您的参与，共同探讨AI时代教育改革与新一代计算机人才培养的课题。如果您有任何疑问，请随时与我们联系。谢谢！</p>
                    <span>顺祝商祺！</span>
                    <span>CSDN创新研究院 2023年3月28日</span>
                </div>
                <div className={style.btn_box}>
                    <a href='/course' className={style.btn}>
                        <span>查看课程大纲</span>
                        <img src={arrow} alt="" />
                    </a>
                </div>

                {isShowToast ? <Toast text={'已复制到剪切板'} /> : ''}
                {/* <Toast text={'已复制到剪切板'} /> */}

            </div >
            <img className={style.btm} src={btm} alt="" />
        </>
    )
}
export default InvitationLetterMain;