import style from './index.module.scss'
const Toast = ({ text }) => {
    return (
        <>
            <div className={style.text}>
                <span> {text}</span>
            </div>
        </>
    )
}

export default Toast;