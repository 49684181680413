import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
  HashRouter
} from "react-router-dom";
import * as ReactDOM from 'react-dom/client';
ReactDOM.createRoot(document.getElementById('root')).render(
  // <HashRouter>
  //   <App />
  // </HashRouter>
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

reportWebVitals();
